@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/radii';

.ProviderLogo {
  width: 92 * tokens.$bpk-one-pixel-rem;
  height: 52 * tokens.$bpk-one-pixel-rem;
  background: tokens.$bpk-surface-default-day;

  img {
    width: 92 * tokens.$bpk-one-pixel-rem;
    height: 52 * tokens.$bpk-one-pixel-rem;

    @include radii.bpk-border-radius-sm;
  }
}
