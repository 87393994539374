@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.RatingsCard {
  min-width: 315.5 * tokens.$bpk-one-pixel-rem;
  min-height: 367 * tokens.$bpk-one-pixel-rem;
  margin: 1.5 * tokens.bpk-spacing-md();

  @include breakpoints.bpk-breakpoint-mobile {
    margin: 1.5 * tokens.bpk-spacing-md() tokens.bpk-spacing-md();
  }

  &__topSection {
    display: flex;
    height: 56 * tokens.$bpk-one-pixel-rem;
    margin-bottom: tokens.bpk-spacing-base();
    align-items: center;
  }

  &__logo {
    width: 92 * tokens.$bpk-one-pixel-rem;
    height: 52 * tokens.$bpk-one-pixel-rem;
    align-items: center;
  }

  &__overallRating {
    flex-grow: 1;
    margin-inline-start: tokens.bpk-spacing-base();
  }

  &__invalidRating {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-inline-start: tokens.bpk-spacing-base();

    &__noRating {
      color: tokens.$bpk-text-primary-day;
    }

    &__noReviews {
      color: tokens.$bpk-text-secondary-day;
    }
  }

  &__middleSection {
    margin-bottom: tokens.bpk-spacing-base();
  }

  &__divided {
    width: 100%;
    height: tokens.$bpk-one-pixel-rem;
    margin-bottom: tokens.bpk-spacing-base();
    background-color: tokens.$bpk-line-day;
    color: tokens.$bpk-line-day;
  }
}
