@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__noPrice {
    justify-content: end;
  }

  &__price {
    span[class*='BpkPrice_bpk-price__subtitle'] {
      text-decoration-line: none;
    }
  }

  &__view {
    color: tokens.$bpk-core-accent-day;
    text-align: right;
    white-space: normal;

    @include typography.bpk-text--base;
  }

  &__buttonArrow {
    display: inline-block;
    margin-left: 10 * tokens.$bpk-one-pixel-rem;

    @include utils.bpk-rtl {
      margin-right: 10 * tokens.$bpk-one-pixel-rem;
      margin-left: 0;
    }
  }
}
