@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

$padding-width: 24 * tokens.$bpk-one-pixel-rem;
$mobile-width: 512 * tokens.$bpk-one-pixel-rem;
$tablet-width: 702 * tokens.$bpk-one-pixel-rem;
$desktop-width: 1042 * tokens.$bpk-one-pixel-rem;

.ProviderList {
  margin: 0 auto;

  @include breakpoints.bpk-breakpoint-mobile {
    margin-right: -(tokens.bpk-spacing-base());
  }

  &__header {
    margin-bottom: 1.5 * tokens.bpk-spacing-md();

    &__remainScrolllerSpace {
      max-width: 80%;
    }
  }

  &__subtitle {
    margin-top: 1.5 * tokens.bpk-spacing-md();
    margin-bottom: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-mobile {
      margin-bottom: 1.5 * tokens.bpk-spacing-md();
    }
  }

  &__desktop {
    display: flex;
    margin: 0 (-1.5 * tokens.bpk-spacing-md());
    flex-flow: row wrap;
    justify-content: flex-start;

    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }

    &__cardContainer {
      @media screen and (min-width: $desktop-width) {
        width: calc(100% / 3);
      }

      @media screen and (min-width: $tablet-width) and (max-width: $desktop-width) {
        width: 50%;
      }

      @media screen and (min-width: $mobile-width) and (max-width: $tablet-width) {
        width: 100%;
      }
    }
  }

  &__mobileScroll {
    @include breakpoints.bpk-breakpoint-above-tablet {
      display: none;
    }

    @include breakpoints.bpk-breakpoint-tablet {
      display: none;
    }

    @include breakpoints.bpk-breakpoint-mobile {
      display: unset;
      margin-right: -(tokens.bpk-spacing-base());
    }
  }

  .Indicator::before {
    background-image: unset;
  }

  .Indicator::after {
    background-image: unset;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include breakpoints.bpk-breakpoint-mobile {
      flex-wrap: nowrap;
    }
  }

  &__container {
    padding: 1.5 * tokens.bpk-spacing-md() tokens.bpk-spacing-md();

    @include breakpoints.bpk-breakpoint-mobile {
      padding: 0;
    }

    @media screen and (min-width: 32rem) and (max-width: 42.625rem) {
      width: 100%;
    }
  }
}
