@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.Rating {
  display: flex;
  margin-bottom: tokens.bpk-spacing-md();
  flex-direction: column;

  &__description {
    display: flex;
    margin-bottom: tokens.bpk-spacing-md();
    flex-direction: row;
    align-items: center;
  }

  &__middle {
    flex: 1;
  }

  &__label {
    color: tokens.$bpk-text-primary-day;
    font-size: tokens.$bpk-font-size-sm;
    line-height: tokens.$bpk-line-height-sm;
  }

  &__rating {
    color: tokens.$bpk-marcomms-dark-sky;
    font-size: tokens.$bpk-font-size-base;
    line-height: tokens.$bpk-line-height-sm;
  }

  &__progress {
    width: 100%;
  }
}
